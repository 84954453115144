import React, { useEffect } from 'react';
import { Header, Container, Grid } from 'semantic-ui-react';
import './view-learn.scss';
import { useGetResourcesQuery } from '../../data/generated/ApolloComponents';
import { AddTikTok } from '../../components/add-tiktok';
import { UrlUtils } from '../../utils/url-utils';

const useTikTokScript = (url: string) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, [url]);
};

export function Learn({ match }: any) {
    const { topic } = match.params;

    const { loading, error, data } = useGetResourcesQuery({
        variables: topic ? {
            topic: topic
        } : undefined
    });

    useTikTokScript('https://www.tiktok.com/embed.js');

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return (
        <Container>
            <Header as="h1">
                Bookmarks
                {
                    (topic && topic.length > 0) &&
                    <>
                        <span> for <span style={{ color: "orange" }}>{topic}</span></span>
                    </>
                }
            </Header>
            {
                (topic && topic.length > 0) &&
                <a href="/learn" onClick={() => UrlUtils.goTo("/learn")}>Show all</a>
            }
            <AddTikTok></AddTikTok>
            <Grid columns={4} padded stackable>
                {
                    data?.getResources?.map((d: any) =>
                        <Grid.Column key={d.id}>
                            <div key={d.id} className={"bookmark"}>
                                <Tiktok id={d.sourceId} author={d.author} title={d.name}></Tiktok>
                                <div className={"bookmark-title"}>{d.name}</div>
                                {
                                    d.topics.map((t: string, index: number) =>
                                        <span onClick={() => UrlUtils.goTo(`/learn/${t}`)}
                                            key={`${d.id}-${index}`}
                                            className={"bookmark-topic"}>{t}
                                        </span>
                                    )
                                }
                            </div>
                        </Grid.Column>
                    )
                }
            </Grid>
        </Container>
    );
}

export function Tiktok(props: any) {
    return (
        <>
            <blockquote
                className="tiktok-embed"
                cite={`https://www.tiktok.com/@${props.author}/video/${props.id}`}
                data-video-id={props.id}
                style={{
                    maxWidth: "325px",
                    minWidth: "325px",
                    minHeight: "288px",
                    maxHeight: "288px",
                    borderRadius: ".5rem"
                }} >
                <section>
                    <a target="_blank"
                        rel="noopener noreferrer"
                        title={`@${props.author}`}
                        href={`https://www.tiktok.com/@${props.author}`}>@{props.author}</a>
                    <p>
                        {props.title}
                    </p>
                </section>
            </blockquote>
        </>
    )
}
