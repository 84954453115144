export class UrlUtils {
    static getDashedName(name: string) {
        return name.toLowerCase().split(' ').join('-').trim();
    }

    static goTo(url: string) {
        window.location.href = url;
    }
}

