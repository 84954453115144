/* tslint:disable:max-line-length */
import {
    IList,
    RelationshipType,
    IGoalResource,
    IPublicProfile,
    IEntity,
    IGoal,
    IUserGoal,
    IUserGoalLink,
    IGoalTree
} from './interfaces';

export class SampleData {
    static sampleUser: IPublicProfile = {
        id: 'jsmith',
        name: 'John Smith',
        uid: '123123123',
        avatar: ''
    };

    static nullGoal: IGoal = {
        id: '',
        name: '',
        description: '',
        resources: [],
        createdBy: SampleData.sampleUser,
        updatedBy: SampleData.sampleUser,
        created: new Date(2000, 1, 1),
        updated: new Date(2000, 1, 1),
        ownedBy: [],
        shareCount: 0,
        likeCount: 0
    };

    static nullUserGoal: IUserGoal = {
        id: '',
        goal: SampleData.nullGoal
    };

    static entities: IEntity[] = [
        {
            id: '1337',
            name: 'Personal finance',
            bannerImage: 'https://content.thriveglobal.com/wp-content/uploads/2019/04/Group-53@3x.png',
            description: 'Budgeting, saving, getting out of debt, credit, investing, and retirement planning.',
            likeCount: 3,
            followCount: 4,
            created: new Date(2020, 5, 1),
            createdBy: SampleData.sampleUser,
            type: '',
            updateHistory: [],
            related: [
                {
                    id: 'rel001',
                    type: RelationshipType.externalLink,
                    name: 'TradingView',
                    link: 'https://www.tradingview.com/'
                },
                {
                    id: 'rel002',
                    type: RelationshipType.externalLink,
                    name: 'Finviz',
                    link: 'https://finviz.com/'
                },
                {
                    id: 'rel003',
                    type: RelationshipType.externalLink,
                    name: 'Yahoo Finance',
                    link: 'https://finance.yahoo.com/'
                }
            ]
        },
        {
            id: '1338',
            name: 'Cooking',
            description: 'Post anything related to cooking here',
            bannerImage:
                'https://media3.s-nbcnews.com/i/newscms/2019_41/3044956/191009-cooking-vegetables-al-1422_ae181a762406ae9dce02dd0d5453d1ba.jpg',
            likeCount: 30,
            followCount: 42,
            created: new Date(2020, 5, 3),
            createdBy: SampleData.sampleUser,
            type: '',
            updateHistory: [],
            related: [
                {
                    id: 'rel004',
                    type: RelationshipType.externalLink,
                    name: 'Yummly',
                    link: 'https://www.yummly.com/'
                },
                {
                    id: 'rel005',
                    type: RelationshipType.externalLink,
                    name: 'Simply',
                    link: 'https://www.simplyrecipes.com/'
                },
                {
                    id: 'rel006',
                    type: RelationshipType.externalLink,
                    name: 'Epicurious',
                    link: 'https://www.epicurious.com/'
                }
            ]
        },
        {
            id: '1339',
            name: 'Zoom plans new blocking features to comply with requests from Chinese government',
            description: 'The feature will let it block users based on their location',
            bannerImage:
                'https://cdn.vox-cdn.com/thumbor/AuyK0L4UIYLqDMm3REplVOQ49RI=/0x0:2040x1360/1820x1213/filters:focal(857x517:1183x843):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/66926639/acastro_200331_1777_zoom_0002.0.0.jpg',
            likeCount: 10,
            followCount: 14,
            created: new Date(2020, 5, 13, 8, 10),
            createdBy: SampleData.sampleUser,
            type: '',
            updateHistory: [],
            related: [
                {
                    id: 'rel004',
                    type: RelationshipType.externalLink,
                    name: 'TheVerge',
                    link:
                        'https://www.theverge.com/2020/6/12/21288995/zoom-blocking-feature-chinese-government-censorship?scrolla=5eb6d68b7fedc32c19ef33b4'
                }
            ]
        },
        {
            id: '1340',
            name: 'Reddit Is Finally Facing Its Legacy of Racism',
            description:
                'Social-media platforms can’t evade the scrutiny that so many other institutions in America are currently under.',
            bannerImage:
                'https://cdn.theatlantic.com/thumbor/Nsqf35Gjw23z8nBhESmDbgFXs0c=/1440x810/filters:format(png)/media/img/mt/2020/06/0620_Katie_Reddit_alt3/original.png',
            likeCount: 3,
            followCount: 8,
            created: new Date(2020, 5, 13, 5, 20),
            createdBy: SampleData.sampleUser,
            type: '',
            updateHistory: [],
            related: [
                {
                    id: 'rel004',
                    type: RelationshipType.externalLink,
                    name: 'The Atlantic',
                    link:
                        'https://www.theatlantic.com/technology/archive/2020/06/reddit-racism-open-letter/612958/?scrolla=5eb6d68b7fedc32c19ef33b4'
                }
            ]
        },
        {
            id: '1399',
            type: 'image',
            name: 'Financial Independence',
            description: '',
            bannerImage:
                'https://cdn.vox-cdn.com/thumbor/AuyK0L4UIYLqDMm3REplVOQ49RI=/0x0:2040x1360/1820x1213/filters:focal(857x517:1183x843):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/66926639/acastro_200331_1777_zoom_0002.0.0.jpg',
            likeCount: 10,
            followCount: 14,
            created: new Date(2020, 5, 13, 8, 10),
            createdBy: SampleData.sampleUser,
            updateHistory: [],
            related: [
                {
                    id: 'rel0024',
                    type: RelationshipType.image,
                    name: 'image',
                    link:
                        'https://cdn.vox-cdn.com/thumbor/AuyK0L4UIYLqDMm3REplVOQ49RI=/0x0:2040x1360/1820x1213/filters:focal(857x517:1183x843):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/66926639/acastro_200331_1777_zoom_0002.0.0.jpg'
                }
            ]
        }
    ];

    static entityList: IList = {
        id: 'list001',
        name: 'Favories',
        description: 'Favorite items',
        created: new Date(2020, 5, 1),
        createdBy: SampleData.sampleUser,
        items: SampleData.entities
    };

    static resources: IGoalResource[] = SampleData.entities.map((i) => {
        return {
            id: `gr${i.id}`,
            createdBy: SampleData.sampleUser,
            resource: i,
            likeCount: Math.floor(Math.random() * 10),
            private: false
        } as IGoalResource;
    });

    static goals: IGoal[] = [
        {
            id: 'goal0001',
            name: 'Be happy',
            description: 'Have a happy life',
            resources: SampleData.resources,
            createdBy: SampleData.sampleUser,
            updatedBy: SampleData.sampleUser,
            ownedBy: [SampleData.sampleUser],
            created: new Date(2020, 5, 13, 5, 20),
            updated: new Date(2020, 5, 13, 5, 20),
            shareCount: 10,
            likeCount: 40
        },
        {
            id: 'goal0002',
            name: 'Financial Independence, Retire Early',
            description: 'Become Financially Independent (FI), which means not having to work for money.',
            resources: [
                {
                    id: `gr1122`,
                    createdBy: SampleData.sampleUser,
                    resource: SampleData.entities[4],
                    likeCount: 82,
                    private: false
                }
            ],
            createdBy: SampleData.sampleUser,
            updatedBy: SampleData.sampleUser,
            ownedBy: [SampleData.sampleUser],
            created: new Date(2020, 5, 13, 5, 20),
            updated: new Date(2020, 5, 13, 5, 20),
            shareCount: 100,
            likeCount: 560
        },
        {
            id: 'goal0003',
            name: 'Day trading',
            description: 'Buy/sell stocks daily',
            resources: [],
            createdBy: SampleData.sampleUser,
            updatedBy: SampleData.sampleUser,
            ownedBy: [SampleData.sampleUser],
            created: new Date(2020, 5, 13, 5, 20),
            updated: new Date(2020, 5, 13, 5, 20),
            shareCount: 10,
            likeCount: 50
        },
        {
            id: 'goal0004',
            name: 'Swing trading',
            description: 'Buy/sell stocks',
            resources: [],
            createdBy: SampleData.sampleUser,
            updatedBy: SampleData.sampleUser,
            ownedBy: [SampleData.sampleUser],
            created: new Date(2020, 5, 13, 5, 20),
            updated: new Date(2020, 5, 13, 5, 20),
            shareCount: 10,
            likeCount: 50
        },
        {
            id: 'goal0005',
            name: 'Cooking',
            description: 'Anything related to cooking',
            resources: [],
            createdBy: SampleData.sampleUser,
            updatedBy: SampleData.sampleUser,
            ownedBy: [SampleData.sampleUser],
            created: new Date(2020, 5, 13, 5, 20),
            updated: new Date(2020, 5, 13, 5, 20),
            shareCount: 10,
            likeCount: 50
        }
    ];

    static userGoals: IUserGoal[] = [
        {
            id: 'ug0001',
            goal: SampleData.getGoal('goal0001')
        },
        {
            id: 'ug0002',
            goal: SampleData.getGoal('goal0002')
        },
        {
            id: 'ug0003',
            goal: SampleData.getGoal('goal0003')
        },
        {
            id: 'ug0004',
            goal: SampleData.getGoal('goal0004')
        },
        {
            id: 'ug0005',
            goal: SampleData.getGoal('goal0005')
        }
    ];

    static userGoalLinks: IUserGoalLink[] = [
        {
            id: 'ugl001',
            preGoalId: 'goal0002',
            postGoalId: 'goal0001'
        },
        {
            id: 'ugl002',
            preGoalId: 'goal0003',
            postGoalId: 'goal0002'
        },
        {
            id: 'ugl003',
            preGoalId: 'goal0004',
            postGoalId: 'goal0002'
        },
        {
            id: 'ugl004',
            preGoalId: 'goal0005',
            postGoalId: 'goal0001'
        }
    ];

    static generateTree(goal: IGoal | undefined, goals: IUserGoal[], links: IUserGoalLink[]): IGoalTree {
        if (goal) {
            return {
                node: SampleData.getUserGoal(goal.id),
                children: SampleData.userGoalLinks
                    .filter((l) => l.postGoalId === goal.id)
                    .map((l) => this.generateTree(SampleData.getGoal(l.preGoalId), goals, links))
            };
        }
        return {
            node: SampleData.nullUserGoal,
            children: []
        };
    }

    static getUserGoals(userId: string): IGoalTree[] {
        return SampleData.getTopGoals(userId).map((g) => {
            return SampleData.generateTree(g, SampleData.userGoals, SampleData.userGoalLinks);
        });
    }

    static getTopGoals(userId: string) {
        const links = SampleData.userGoalLinks;
        const topGoals: IGoal[] = [];
        links.forEach((link) => {
            if (!links.find((l) => l.preGoalId === link.postGoalId)) {
                const goal: IGoal | undefined = SampleData.goals.find((g) => g.id === link.postGoalId);
                if (goal && !topGoals.find((gf) => gf.id === goal.id)) {
                    topGoals.push(goal);
                }
            }
        });
        SampleData.goals.forEach((g) => {
            if (!links.find((l) => l.preGoalId === g.id || l.postGoalId === g.id)) {
                if (!topGoals.find((gf) => gf.id === g.id)) {
                    topGoals.push(g);
                }
            }
        });
        return topGoals;
    }

    static getGoal(goalId: string) {
        const matchingGoal = SampleData.goals.find((g) => g.id === goalId);
        if (matchingGoal) {
            return matchingGoal;
        } else {
            return SampleData.nullGoal;
        }
    }

    static getUserGoal(goalId: string) {
        const matchingUserGoal = SampleData.userGoals.find((g) => g.goal.id === goalId);
        if (matchingUserGoal) {
            return matchingUserGoal;
        } else {
            return SampleData.nullUserGoal;
        }
    }

    static tiktoks = [
        {
            id: 100001,
            name: "My take on making content and bringing value",
            author: "garyvee",
            link: "https://www.tiktok.com/@garyvee/video/6837483203092122886",
            source: "tiktok",
            sourceId: "6837483203092122886",
            topics: ["business", "content-marketing", "marketing", "entrepreneurship"]
        },
        {
            id: 100002,
            name: "If you are 19 and just getting started watch this",
            author: "jasoncapital",
            link: "https://www.tiktok.com/@jasoncapital/video/6848396264065830150",
            source: "tiktok",
            sourceId: "6848396264065830150",
            topics: ["career-development", "marketing", "learning", "copywriting"]
        },
        {
            id: 100003,
            name: "Google 79/21 GaryVee' for the full video",
            author: "garyvee",
            link: "https://www.tiktok.com/@garyvee/video/6848610594677951749",
            source: "tiktok",
            sourceId: "6848610594677951749",
            topics: ["business", "content-marketing", "marketing", "entrepreneurship", "tiktok", "trends", "strategy"]
        },
        {
            id: 100004,
            name: "Spy on your competitors! pt 3",
            author: "socialtypro",
            link: "https://www.tiktok.com/@socialtypro/video/6848248237376228614",
            source: "tiktok",
            sourceId: "6848248237376228614",
            topics: ["business", "marketing", "seo", "entrepreneurship"]
        },
    ];

    static getTikToks(topic?: string) {
        if (topic && (topic.length > 0)) {
            return SampleData.tiktoks.filter((tt) => tt.topics.indexOf(topic) >= 0);
        }
        else {
            return SampleData.tiktoks;
        }
    }
}
