import React from 'react';
import { Container, Tab } from 'semantic-ui-react';
import './view-profile.scss';
import { GoalTree } from '../../components/goal-tree';
import { GoalList } from '../../components/goal-list';
import { NewGoal } from '../../components/new-goal';
import {
    useGetUserGoalsQuery,
    useGetUserQuery
} from '../../data/generated/ApolloComponents'

export function Profile({ match }: any) {
    const { username } = match.params;
    return (
        <Container>
            <GoalsList user={username} />
        </Container>
    );
}

export function GoalsList(props: any) {

    const { loading, error, data } = useGetUserGoalsQuery({
        variables: {
            userId: props.user
        },
        fetchPolicy: 'network-only'
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    const tabs = [
        {
            menuItem: 'All goals',
            render: () => (
                <Tab.Pane>
                    <GoalTree user={props.user} goals={data!.getUserGoals}></GoalTree>
                </Tab.Pane>
            )
        },
        {
            menuItem: 'My active goals',
            render: () => (
                <Tab.Pane>
                    <GoalList goals={data!.getUserGoals}></GoalList>
                </Tab.Pane>
            )
        },
        { menuItem: 'Completed', render: () => <Tab.Pane>Completed</Tab.Pane> },
        { menuItem: 'Saved', render: () => <Tab.Pane>Saved</Tab.Pane> }
    ];
    return (
        <>
            <NewGoal goals={data!.getUserGoals}></NewGoal>
            <Tab panes={tabs} />
        </>
    );
}

export function UserName(props: any) {
    const { loading, error, data } = useGetUserQuery({
        variables: {
            userId: props.username
        }
    });

    if (loading) return <span>...</span>;
    if (error) return <span>{error.graphQLErrors[0].message}</span>;

    return <span>{data!.getUser!.name}</span>;
}
