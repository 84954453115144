import React from 'react';
import { Container, Button, Icon } from 'semantic-ui-react';
import './component-add-tiktok.scss';
import { Link } from "react-router-dom";

export function AddTikTok() {
    return (
        <Container>
            <Button icon labelPosition='left' as={Link} to="/resource/new">
                <Icon name='plus' />
                Add TikTok
            </Button>
        </Container>
    );
}