import React from 'react';
import './component-goal-tree.scss';
import { Link } from 'react-router-dom';

export interface IGoal {
    id: string;
    name: string;
    complete: boolean;
    kudosCount: number;
    path: string;
    quantity: number;
    timespan: string;
    unit: string;
    whys: IGoal[];
    hows: IGoal[];
}

export function GoalTree(props: any) {
    return (
        <div className="goal-list">
            {
                props.goals
                    .filter((g: IGoal) => !g.whys || g.whys.length === 0)
                    .map((g: IGoal) => <GoalBranch key={g.id} goal={g} goals={props.goals} user={props.user} />)
            }
        </div>
    );
}

function GoalBranch(props: any) {
    const subGoals = props.goals
        .filter((g: IGoal) => {
            return (g.whys.find((hg: IGoal) => hg.id === props.goal.id) !== undefined)
        });
    return (
        <div className="goal-list-item">
            <Link to={`${props.user}/goals/${props.goal.id}`}>{props.goal.name}</Link>
            {
                (subGoals.length > 0) ?
                    <div className="goal-list inner">
                        {
                            subGoals.map((g: IGoal) =>
                                <GoalBranch key={g.id} goal={g} goals={props.goals} user={props.user} />
                            )
                        }
                    </div>
                    : undefined
            }
        </div>
    );
}

