import React from 'react';
import { Form } from 'semantic-ui-react';
import './component-new-goal.scss';
import { IGoal } from '../../data/interfaces';
import { UserService } from '../../services/user-service';
import {
    useNewUserGoalMutation,
    GetUserGoalsDocument
} from '../../data/generated/ApolloComponents'

export function NewGoal(props: any) {

    const [goalName, setGoalName] = React.useState("");
    const [parentGoal, setParentGoal] = React.useState("");

    const [newUserGoalMutation] = useNewUserGoalMutation({
        refetchQueries: [
            {
                query: GetUserGoalsDocument,
                variables: {
                    userId: UserService.currentUser?.id
                }
            }
        ]
    });

    const goalSelect = props.goals?.map((g: IGoal) => {
        return {
            key: g.id,
            text: g.name,
            value: g.id
        };
    });

    if (UserService.currentUser) {
        return (
            <Form onSubmit={e => {
                e.preventDefault();
                let goalVariables = {
                    userId: UserService.currentUser?.id,
                    name: goalName,
                    description: ""
                };
                if (parentGoal && parentGoal.length > 0) {
                    goalVariables = {
                        ...goalVariables,
                        ...{
                            linkedGoalId: parentGoal,
                            isLinkedGoalWhy: true
                        }
                    };
                }
                newUserGoalMutation({
                    variables: goalVariables,
                });
            }}>
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        value={goalName}
                        onChange={e => setGoalName(e.target.value)}
                        label="Goal name"
                        placeholder="Goal name"
                    />
                    <Form.Select
                        fluid
                        value={parentGoal}
                        onChange={(e, { value }) => setParentGoal(`${value}`)}
                        label='Why?'
                        options={goalSelect ?? []}
                    />
                    <Form.Button
                        fluid
                        type="submit"
                        style={{ marginTop: "1.8rem" }}>
                        Add goal
                    </Form.Button>
                </Form.Group>
            </Form>
        );
    }
    else {
        return null;
    }
}
