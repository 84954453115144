import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: 'Query';
  getUser?: Maybe<PublicUserProfile>;
  getUserByUid?: Maybe<PublicUserProfile>;
  getUserGoals?: Maybe<Array<Maybe<Goal>>>;
  getGoal?: Maybe<Goal>;
  getResources?: Maybe<Array<Maybe<Resource>>>;
};


export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserByUidArgs = {
  uid: Scalars['ID'];
};


export type QueryGetUserGoalsArgs = {
  userId: Scalars['ID'];
};


export type QueryGetGoalArgs = {
  goalId: Scalars['ID'];
  userId?: Maybe<Scalars['ID']>;
};


export type QueryGetResourcesArgs = {
  topic?: Maybe<Scalars['ID']>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['ID']>;
  avatar?: Maybe<Scalars['String']>;
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  name: Scalars['String'];
  complete?: Maybe<Scalars['Boolean']>;
  kudosCount?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  timespan?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  whys?: Maybe<Array<Maybe<Goal>>>;
  hows?: Maybe<Array<Maybe<Goal>>>;
  resources?: Maybe<Array<Maybe<Resource>>>;
  messages?: Maybe<Array<Maybe<Message>>>;
  followerCount?: Maybe<Scalars['Int']>;
  likeCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  replyTo?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  newUser?: Maybe<Scalars['String']>;
  newUserGoal?: Maybe<Scalars['String']>;
  linkUserGoals?: Maybe<Scalars['String']>;
  followGoal?: Maybe<Goal>;
  likeGoal?: Maybe<Goal>;
  unlikeGoal?: Maybe<Goal>;
  addResource?: Maybe<Resource>;
};


export type MutationNewUserArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type MutationNewUserGoalArgs = {
  userId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  existingGoalId?: Maybe<Scalars['ID']>;
  linkedGoalId?: Maybe<Scalars['ID']>;
  isLinkedGoalWhy?: Maybe<Scalars['Boolean']>;
  isLinkedGoalHow?: Maybe<Scalars['Boolean']>;
};


export type MutationLinkUserGoalsArgs = {
  userId?: Maybe<Scalars['ID']>;
  parentGoalId?: Maybe<Scalars['ID']>;
  childGoalId?: Maybe<Scalars['ID']>;
};


export type MutationFollowGoalArgs = {
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationLikeGoalArgs = {
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUnlikeGoalArgs = {
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddResourceArgs = {
  name?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE'
}


export type AddResourceMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type AddResourceMutation = (
  { __typename?: 'Mutation' }
  & { addResource?: Maybe<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'name' | 'author' | 'type' | 'source' | 'sourceId' | 'link' | 'topics'>
  )> }
);

export type FollowGoalMutationVariables = Exact<{
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type FollowGoalMutation = (
  { __typename?: 'Mutation' }
  & { followGoal?: Maybe<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'name'>
  )> }
);

export type LikeGoalMutationVariables = Exact<{
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type LikeGoalMutation = (
  { __typename?: 'Mutation' }
  & { likeGoal?: Maybe<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'name'>
  )> }
);

export type LinkUserGoalsMutationVariables = Exact<{
  userId: Scalars['ID'];
  parentGoalId: Scalars['ID'];
  childGoalId: Scalars['ID'];
}>;


export type LinkUserGoalsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'linkUserGoals'>
);

export type NewUserMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}>;


export type NewUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'newUser'>
);

export type NewUserGoalMutationVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  existingGoalId?: Maybe<Scalars['ID']>;
  linkedGoalId?: Maybe<Scalars['ID']>;
  isLinkedGoalWhy?: Maybe<Scalars['Boolean']>;
  isLinkedGoalHow?: Maybe<Scalars['Boolean']>;
}>;


export type NewUserGoalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'newUserGoal'>
);

export type UnlikeGoalMutationVariables = Exact<{
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type UnlikeGoalMutation = (
  { __typename?: 'Mutation' }
  & { unlikeGoal?: Maybe<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'name'>
  )> }
);

export type GetGoalQueryVariables = Exact<{
  goalId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetGoalQuery = (
  { __typename?: 'Query' }
  & { getGoal?: Maybe<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'name' | 'complete' | 'path' | 'quantity' | 'timespan' | 'unit' | 'kudosCount' | 'description' | 'likeCount' | 'followerCount'>
    & { whys?: Maybe<Array<Maybe<(
      { __typename?: 'Goal' }
      & Pick<Goal, 'id' | 'name'>
    )>>>, hows?: Maybe<Array<Maybe<(
      { __typename?: 'Goal' }
      & Pick<Goal, 'id' | 'name'>
    )>>> }
  )> }
);

export type GetResourcesQueryVariables = Exact<{
  topic?: Maybe<Scalars['ID']>;
}>;


export type GetResourcesQuery = (
  { __typename?: 'Query' }
  & { getResources?: Maybe<Array<Maybe<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'name' | 'author' | 'source' | 'link' | 'sourceId' | 'topics'>
  )>>> }
);

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'PublicUserProfile' }
    & Pick<PublicUserProfile, 'id' | 'name'>
  )> }
);

export type GetUserByUidQueryVariables = Exact<{
  uid: Scalars['ID'];
}>;


export type GetUserByUidQuery = (
  { __typename?: 'Query' }
  & { getUserByUid?: Maybe<(
    { __typename?: 'PublicUserProfile' }
    & Pick<PublicUserProfile, 'id' | 'name' | 'uid' | 'avatar'>
  )> }
);

export type GetUserGoalsQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserGoalsQuery = (
  { __typename?: 'Query' }
  & { getUserGoals?: Maybe<Array<Maybe<(
    { __typename?: 'Goal' }
    & Pick<Goal, 'id' | 'name' | 'complete' | 'kudosCount' | 'path' | 'quantity' | 'timespan' | 'unit'>
    & { whys?: Maybe<Array<Maybe<(
      { __typename?: 'Goal' }
      & Pick<Goal, 'id' | 'name'>
    )>>>, hows?: Maybe<Array<Maybe<(
      { __typename?: 'Goal' }
      & Pick<Goal, 'id' | 'name'>
    )>>> }
  )>>> }
);


export const AddResourceDocument = gql`
    mutation AddResource($name: String, $author: String, $type: String, $source: String, $sourceId: ID, $link: String, $topics: [String]) {
  addResource(name: $name, author: $author, type: $type, source: $source, sourceId: $sourceId, link: $link, topics: $topics) {
    id
    name
    author
    type
    source
    sourceId
    link
    topics
  }
}
    `;
export type AddResourceMutationFn = ApolloReactCommon.MutationFunction<AddResourceMutation, AddResourceMutationVariables>;

/**
 * __useAddResourceMutation__
 *
 * To run a mutation, you first call `useAddResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addResourceMutation, { data, loading, error }] = useAddResourceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      author: // value for 'author'
 *      type: // value for 'type'
 *      source: // value for 'source'
 *      sourceId: // value for 'sourceId'
 *      link: // value for 'link'
 *      topics: // value for 'topics'
 *   },
 * });
 */
export function useAddResourceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddResourceMutation, AddResourceMutationVariables>) {
        return ApolloReactHooks.useMutation<AddResourceMutation, AddResourceMutationVariables>(AddResourceDocument, baseOptions);
      }
export type AddResourceMutationHookResult = ReturnType<typeof useAddResourceMutation>;
export type AddResourceMutationResult = ApolloReactCommon.MutationResult<AddResourceMutation>;
export type AddResourceMutationOptions = ApolloReactCommon.BaseMutationOptions<AddResourceMutation, AddResourceMutationVariables>;
export const FollowGoalDocument = gql`
    mutation FollowGoal($goalId: ID!, $userId: ID!) {
  followGoal(goalId: $goalId, userId: $userId) {
    id
    name
  }
}
    `;
export type FollowGoalMutationFn = ApolloReactCommon.MutationFunction<FollowGoalMutation, FollowGoalMutationVariables>;

/**
 * __useFollowGoalMutation__
 *
 * To run a mutation, you first call `useFollowGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followGoalMutation, { data, loading, error }] = useFollowGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFollowGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FollowGoalMutation, FollowGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<FollowGoalMutation, FollowGoalMutationVariables>(FollowGoalDocument, baseOptions);
      }
export type FollowGoalMutationHookResult = ReturnType<typeof useFollowGoalMutation>;
export type FollowGoalMutationResult = ApolloReactCommon.MutationResult<FollowGoalMutation>;
export type FollowGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<FollowGoalMutation, FollowGoalMutationVariables>;
export const LikeGoalDocument = gql`
    mutation LikeGoal($goalId: ID!, $userId: ID!) {
  likeGoal(goalId: $goalId, userId: $userId) {
    id
    name
  }
}
    `;
export type LikeGoalMutationFn = ApolloReactCommon.MutationFunction<LikeGoalMutation, LikeGoalMutationVariables>;

/**
 * __useLikeGoalMutation__
 *
 * To run a mutation, you first call `useLikeGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeGoalMutation, { data, loading, error }] = useLikeGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useLikeGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LikeGoalMutation, LikeGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<LikeGoalMutation, LikeGoalMutationVariables>(LikeGoalDocument, baseOptions);
      }
export type LikeGoalMutationHookResult = ReturnType<typeof useLikeGoalMutation>;
export type LikeGoalMutationResult = ApolloReactCommon.MutationResult<LikeGoalMutation>;
export type LikeGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<LikeGoalMutation, LikeGoalMutationVariables>;
export const LinkUserGoalsDocument = gql`
    mutation LinkUserGoals($userId: ID!, $parentGoalId: ID!, $childGoalId: ID!) {
  linkUserGoals(userId: $userId, parentGoalId: $parentGoalId, childGoalId: $childGoalId)
}
    `;
export type LinkUserGoalsMutationFn = ApolloReactCommon.MutationFunction<LinkUserGoalsMutation, LinkUserGoalsMutationVariables>;

/**
 * __useLinkUserGoalsMutation__
 *
 * To run a mutation, you first call `useLinkUserGoalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkUserGoalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkUserGoalsMutation, { data, loading, error }] = useLinkUserGoalsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      parentGoalId: // value for 'parentGoalId'
 *      childGoalId: // value for 'childGoalId'
 *   },
 * });
 */
export function useLinkUserGoalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkUserGoalsMutation, LinkUserGoalsMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkUserGoalsMutation, LinkUserGoalsMutationVariables>(LinkUserGoalsDocument, baseOptions);
      }
export type LinkUserGoalsMutationHookResult = ReturnType<typeof useLinkUserGoalsMutation>;
export type LinkUserGoalsMutationResult = ApolloReactCommon.MutationResult<LinkUserGoalsMutation>;
export type LinkUserGoalsMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkUserGoalsMutation, LinkUserGoalsMutationVariables>;
export const NewUserDocument = gql`
    mutation NewUser($id: ID!, $name: String, $email: String) {
  newUser(id: $id, name: $name, email: $email)
}
    `;
export type NewUserMutationFn = ApolloReactCommon.MutationFunction<NewUserMutation, NewUserMutationVariables>;

/**
 * __useNewUserMutation__
 *
 * To run a mutation, you first call `useNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newUserMutation, { data, loading, error }] = useNewUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useNewUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewUserMutation, NewUserMutationVariables>) {
        return ApolloReactHooks.useMutation<NewUserMutation, NewUserMutationVariables>(NewUserDocument, baseOptions);
      }
export type NewUserMutationHookResult = ReturnType<typeof useNewUserMutation>;
export type NewUserMutationResult = ApolloReactCommon.MutationResult<NewUserMutation>;
export type NewUserMutationOptions = ApolloReactCommon.BaseMutationOptions<NewUserMutation, NewUserMutationVariables>;
export const NewUserGoalDocument = gql`
    mutation NewUserGoal($userId: ID, $name: String, $description: String, $existingGoalId: ID, $linkedGoalId: ID, $isLinkedGoalWhy: Boolean, $isLinkedGoalHow: Boolean) {
  newUserGoal(userId: $userId, name: $name, description: $description, existingGoalId: $existingGoalId, linkedGoalId: $linkedGoalId, isLinkedGoalWhy: $isLinkedGoalWhy, isLinkedGoalHow: $isLinkedGoalHow)
}
    `;
export type NewUserGoalMutationFn = ApolloReactCommon.MutationFunction<NewUserGoalMutation, NewUserGoalMutationVariables>;

/**
 * __useNewUserGoalMutation__
 *
 * To run a mutation, you first call `useNewUserGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewUserGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newUserGoalMutation, { data, loading, error }] = useNewUserGoalMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      existingGoalId: // value for 'existingGoalId'
 *      linkedGoalId: // value for 'linkedGoalId'
 *      isLinkedGoalWhy: // value for 'isLinkedGoalWhy'
 *      isLinkedGoalHow: // value for 'isLinkedGoalHow'
 *   },
 * });
 */
export function useNewUserGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<NewUserGoalMutation, NewUserGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<NewUserGoalMutation, NewUserGoalMutationVariables>(NewUserGoalDocument, baseOptions);
      }
export type NewUserGoalMutationHookResult = ReturnType<typeof useNewUserGoalMutation>;
export type NewUserGoalMutationResult = ApolloReactCommon.MutationResult<NewUserGoalMutation>;
export type NewUserGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<NewUserGoalMutation, NewUserGoalMutationVariables>;
export const UnlikeGoalDocument = gql`
    mutation UnlikeGoal($goalId: ID!, $userId: ID!) {
  unlikeGoal(goalId: $goalId, userId: $userId) {
    id
    name
  }
}
    `;
export type UnlikeGoalMutationFn = ApolloReactCommon.MutationFunction<UnlikeGoalMutation, UnlikeGoalMutationVariables>;

/**
 * __useUnlikeGoalMutation__
 *
 * To run a mutation, you first call `useUnlikeGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeGoalMutation, { data, loading, error }] = useUnlikeGoalMutation({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnlikeGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlikeGoalMutation, UnlikeGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<UnlikeGoalMutation, UnlikeGoalMutationVariables>(UnlikeGoalDocument, baseOptions);
      }
export type UnlikeGoalMutationHookResult = ReturnType<typeof useUnlikeGoalMutation>;
export type UnlikeGoalMutationResult = ApolloReactCommon.MutationResult<UnlikeGoalMutation>;
export type UnlikeGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlikeGoalMutation, UnlikeGoalMutationVariables>;
export const GetGoalDocument = gql`
    query GetGoal($goalId: ID!, $userId: ID!) {
  getGoal(goalId: $goalId, userId: $userId) {
    id
    name
    complete
    path
    quantity
    timespan
    unit
    kudosCount
    description
    likeCount
    followerCount
    whys {
      id
      name
    }
    hows {
      id
      name
    }
  }
}
    `;

/**
 * __useGetGoalQuery__
 *
 * To run a query within a React component, call `useGetGoalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoalQuery({
 *   variables: {
 *      goalId: // value for 'goalId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetGoalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoalQuery, GetGoalQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, baseOptions);
      }
export function useGetGoalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoalQuery, GetGoalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoalQuery, GetGoalQueryVariables>(GetGoalDocument, baseOptions);
        }
export type GetGoalQueryHookResult = ReturnType<typeof useGetGoalQuery>;
export type GetGoalLazyQueryHookResult = ReturnType<typeof useGetGoalLazyQuery>;
export type GetGoalQueryResult = ApolloReactCommon.QueryResult<GetGoalQuery, GetGoalQueryVariables>;
export const GetResourcesDocument = gql`
    query GetResources($topic: ID) {
  getResources(topic: $topic) {
    id
    name
    author
    source
    link
    sourceId
    topics
  }
}
    `;

/**
 * __useGetResourcesQuery__
 *
 * To run a query within a React component, call `useGetResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesQuery({
 *   variables: {
 *      topic: // value for 'topic'
 *   },
 * });
 */
export function useGetResourcesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, baseOptions);
      }
export function useGetResourcesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetResourcesQuery, GetResourcesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetResourcesQuery, GetResourcesQueryVariables>(GetResourcesDocument, baseOptions);
        }
export type GetResourcesQueryHookResult = ReturnType<typeof useGetResourcesQuery>;
export type GetResourcesLazyQueryHookResult = ReturnType<typeof useGetResourcesLazyQuery>;
export type GetResourcesQueryResult = ApolloReactCommon.QueryResult<GetResourcesQuery, GetResourcesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: ID!) {
  getUser(userId: $userId) {
    id
    name
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserByUidDocument = gql`
    query GetUserByUid($uid: ID!) {
  getUserByUid(uid: $uid) {
    id
    name
    uid
    avatar
  }
}
    `;

/**
 * __useGetUserByUidQuery__
 *
 * To run a query within a React component, call `useGetUserByUidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByUidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByUidQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserByUidQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserByUidQuery, GetUserByUidQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserByUidQuery, GetUserByUidQueryVariables>(GetUserByUidDocument, baseOptions);
      }
export function useGetUserByUidLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserByUidQuery, GetUserByUidQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserByUidQuery, GetUserByUidQueryVariables>(GetUserByUidDocument, baseOptions);
        }
export type GetUserByUidQueryHookResult = ReturnType<typeof useGetUserByUidQuery>;
export type GetUserByUidLazyQueryHookResult = ReturnType<typeof useGetUserByUidLazyQuery>;
export type GetUserByUidQueryResult = ApolloReactCommon.QueryResult<GetUserByUidQuery, GetUserByUidQueryVariables>;
export const GetUserGoalsDocument = gql`
    query GetUserGoals($userId: ID!) {
  getUserGoals(userId: $userId) {
    id
    name
    complete
    kudosCount
    path
    quantity
    timespan
    unit
    whys {
      id
      name
    }
    hows {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserGoalsQuery__
 *
 * To run a query within a React component, call `useGetUserGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserGoalsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserGoalsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserGoalsQuery, GetUserGoalsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserGoalsQuery, GetUserGoalsQueryVariables>(GetUserGoalsDocument, baseOptions);
      }
export function useGetUserGoalsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserGoalsQuery, GetUserGoalsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserGoalsQuery, GetUserGoalsQueryVariables>(GetUserGoalsDocument, baseOptions);
        }
export type GetUserGoalsQueryHookResult = ReturnType<typeof useGetUserGoalsQuery>;
export type GetUserGoalsLazyQueryHookResult = ReturnType<typeof useGetUserGoalsLazyQuery>;
export type GetUserGoalsQueryResult = ApolloReactCommon.QueryResult<GetUserGoalsQuery, GetUserGoalsQueryVariables>;