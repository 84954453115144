import React, { createRef, RefObject } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { Container, Button } from 'semantic-ui-react';
import './view-editor.scss';
import { produce } from 'immer';
import $ from 'jquery';

interface ICoordinate {
    x: number | undefined;
    y: number | undefined;
}

interface IEditorState {
    tooltip: ICoordinate;
}

enum ToolTipAction {
    none,
    mention,
    reference
}

export class Editor extends React.Component<any, any> {
    private editorRef: RefObject<HTMLElement> = createRef();
    private self: Editor | undefined;

    constructor(props: any) {
        super(props);
        this.self = this;
        this.state = {
            editorContent: '',
            toolTip: {
                x: undefined,
                y: undefined,
                actionType: ToolTipAction.none,
                selectionIndex: -1,
                visible: false,
                items: ['one', 'two', 'three', 'four']
            }
        };
    }

    componentDidMount() {
        this.self = this;
        // TODO: Refactor and avoid using jQuery directly
        $('.editor').trigger('focus');
    }

    format(command: string, value?: string) {
        document.execCommand(command, false, value);
    }

    setUrl() {
        const url = 'http://bing.com';
        const sText = document.getSelection();
        document.execCommand('insertHTML', false, '<a href="' + url + '" target="_blank">' + sText + '</a>');
    }

    editorUpdate(event: ContentEditableEvent, editor: Editor | undefined) {
        const caretCoords = getSelectionCoords();
        const typedCharacter = (event.nativeEvent as any).data;
        switch (typedCharacter) {
            case '@':
            case '/':
            case '#':
                this.setState({
                    editorContent: event.target.value,
                    toolTip: produce(this.state.toolTip, (t: any) => {
                        t.x = caretCoords.x + 3;
                        t.y = caretCoords.y + 26;
                        t.visible = true;
                        t.selectionIndex = -1;
                        t.actionType = ToolTipAction.mention; //TODO: Set type based on launch character
                    })
                });
                break;
            case ' ':
            case null:
            case undefined:
                this.setState({
                    editorContent: event.target.value,
                    toolTip: produce(this.state.toolTip, (t: any) => {
                        t.visible = false;
                    })
                });
                break;
            default:
                this.setState({
                    editorContent: event.target.value
                });
                break;
        }
        // console.log(typedCharacter, typedCharacter ? true : false, getSelectionCoords());
    }

    editorKeyDown(event: React.KeyboardEvent) {
        switch (event.keyCode) {
            case 27: // ESCAPE
                if (this.state.toolTip.visible) {
                    this.setState({
                        toolTip: produce(this.state.toolTip, (t: any) => {
                            t.visible = false;
                        })
                    });
                }
                break;
            case 13: // ENTER
                if (this.state.toolTip.visible) {
                    event.preventDefault();
                    this.setState({
                        //TODO: Figure out why the caret goes to the end after inserting new content
                        //TODO: Support adding links (not just plain text)
                        editorContent: insertTextAtCaret(this.state.toolTip.items[this.state.toolTip.selectionIndex]),
                        toolTip: produce(this.state.toolTip, (t: any) => {
                            t.visible = false;
                        })
                    });
                }
                break;
            case 38: // UP
                if (this.state.toolTip.visible) {
                    event.preventDefault();
                    this.setState({
                        toolTip: produce(this.state.toolTip, (t: any) => {
                            t.selectionIndex = this.state.toolTip.selectionIndex - 1;
                        })
                    });
                }
                break;
            case 37: // LEFT ARROW
            case 39: // RIGHT ARROW
                if (this.state.toolTip.visible) {
                    this.setState({
                        toolTip: produce(this.state.toolTip, (t: any) => {
                            t.visible = false;
                        })
                    });
                }
                break;
            case 40: // DOWN
                if (this.state.toolTip.visible) {
                    event.preventDefault();
                    this.setState({
                        toolTip: produce(this.state.toolTip, (t: any) => {
                            t.selectionIndex = this.state.toolTip.selectionIndex + 1;
                        })
                    });
                }
                break;
        }
        // console.log('KEY', event.keyCode);
    }

    render() {
        return (
            <Container>
                <h1>Editor</h1>
                <Button onClick={() => this.format('bold')}>Bold</Button>
                <Button onClick={() => this.format('italic')}>Italic</Button>
                <Button onClick={() => this.format('insertunorderedlist')}>Bullet</Button>
                <Button onClick={() => this.setUrl()}>Link</Button>
                <ContentEditable
                    onKeyDown={(event) => this.editorKeyDown(event)}
                    innerRef={this.editorRef}
                    className={'editor'}
                    html={this.state.editorContent}
                    disabled={false} // use true to disable editing
                    onChange={(event) => this.editorUpdate(event, this)} // handle innerHTML change
                    tagName="article" // Use a custom HTML tag (uses a div by default)
                />
                <div
                    className={`
                        tooltip 
                        ${this.state.toolTip.visible ? 'active' : ''}
                    `}
                    style={{
                        top: `${this.state?.toolTip?.y}px`,
                        left: `${this.state?.toolTip?.x}px`
                    }}
                >
                    {this.state.toolTip &&
                        this.state.toolTip.items &&
                        this.state.toolTip.items.map((item: string, index: number) => {
                            return (
                                <div
                                    className={`
                                            tooltip-item 
                                            ${index === this.state.toolTip?.selectionIndex ? 'active' : ''}
                                        `}
                                    key={index}
                                >
                                    {item}
                                </div>
                            );
                        })}
                </div>
            </Container>
        );
    }
}

/*
function setCaretPosition(elem: any, caretPos: number) {
    if (elem !== null) {
        if (elem.createTextRange) {
            const range = elem.createTextRange();
            range.move('character', caretPos);
            range.select();
        }
        else {
            if (elem.selectionStart) {
                elem.focus();
                elem.setSelectionRange(caretPos, caretPos);
            }
            else
                elem.focus();
        }
    }
}



function insertTextAtCaretX(text: string) {
    let sel, range;
    const doc: any = document;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel?.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            range.deleteContents();
            range.insertNode(document.createTextNode(text));
        }
    } else if (doc.selection && doc.selection.createRange) {
        doc.selection.createRange().text = text;
    }
}
*/
function insertTextAtCaret(text: string): string | null {
    let sel, range: any;
    const doc: any = document;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel?.getRangeAt && sel.rangeCount) {
            range = sel.getRangeAt(0);
            const container: Element = document.getElementsByClassName("editor")[0];
            let output: string = "";
            const childElements = container.childNodes;
            const start: Element = range.startContainer;
            childElements.forEach((e, index) => {
                const eany: any = e;
                if (eany === start) {
                    if (eany.nodeType === 3) { // TEXT
                        output += insertString(eany.textContent, text, range.startOffset);
                    }
                    else {
                        range.deleteContents();
                        range.insertNode(document.createTextNode(text));
                        eany.textContent = insertString(eany.textContent, text, range.startOffset);
                        output += eany.outerHTML;
                        // TODO: Recreate HTML element (div) with the new text
                    }
                }
                else if (eany === start.parentElement) {
                    const eanyp: any = start.parentElement;
                    if (eanyp.nodeType === 3) { // TEXT
                        output += insertString(eanyp.textContent, text, eanyp.startOffset);
                    }
                    else {
                        eanyp.textContent = insertString(eanyp.textContent, text, range.startOffset);
                        output += eany.outerHTML;
                        // TODO: Recreate HTML element (div) with the new text
                    }
                }
                else {
                    if (eany.nodeType === 3) {
                        output += eany.textContent;
                    }
                    else {
                        output += eany.outerHTML;
                    }
                }
            });
            return output;
        }
    } else if (doc.selection && doc.selection.createRange) {
        doc.selection.createRange().text = text;
        // TODO: Support for other browsers
    }
    return null;
}

function insertString(original: string | null, insert: string, position: number) {
    // console.log("insertString", original, insert, position);
    const inputText = original ?? "";
    return [inputText.slice(0, position), insert, inputText.slice(position)].join('');
}
/*
function getCaretCharacterOffsetWithin() {
    const element: any = document.getElementsByClassName('editor')[0];
    let caretOffset = 0;
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let sel;
    if (typeof win.getSelection != 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            const range = win.getSelection().getRangeAt(0);
            const preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }
    } else if ((sel = doc.selection) && sel.type != 'Control') {
        const textRange = sel.createRange();
        const preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
    }
    console.log(caretOffset);
    return caretOffset;
}
*/
function getSelectionCoords(win?: any) {
    win = win || window;
    const doc = win.document;
    let sel = doc.selection,
        range,
        rects,
        rect;
    let x = 0,
        y = 0;
    if (sel) {
        if (sel.type !== 'Control') {
            range = sel.createRange();
            range.collapse(true);
            x = range.boundingLeft;
            y = range.boundingTop;
        }
    } else if (win.getSelection) {
        sel = win.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0).cloneRange();
            if (range.getClientRects) {
                range.collapse(true);
                rects = range.getClientRects();
                if (rects.length > 0) {
                    rect = rects[0];
                }
                x = rect?.left;
                y = rect?.top;
            }
            // Fall back to inserting a temporary element
            if (x === 0 && y === 0) {
                const span = doc.createElement('span');
                if (span.getClientRects) {
                    // Ensure span has dimensions and position by
                    // adding a zero-width space character
                    span.appendChild(doc.createTextNode('\u200b'));
                    range.insertNode(span);
                    rect = span.getClientRects()[0];
                    x = rect.left;
                    y = rect.top;
                    const spanParent = span.parentNode;
                    spanParent.removeChild(span);

                    // Glue any broken text nodes back together
                    spanParent.normalize();
                }
            }
        }
    }
    return { x: x, y: y };
}
