import React from 'react';
import { Header } from 'semantic-ui-react';
import './component-goal-list.scss';
// import { Link } from "react-router-dom";

export function GoalList(props: any) {
    return (
        <>
            <Header>Goal list</Header>
        </>
    );
}