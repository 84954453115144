import React, { RefObject } from 'react';
import firebase from 'firebase';
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import { Header, Segment, List, Button, Container, Image } from 'semantic-ui-react';
import { ApolloProvider } from '@apollo/react-hooks';

import './App.scss';
import { WIP } from './views/wip/view-wip';
import { Home } from './views/home/view-home';
import { LogIn } from './views/login/view-login';
import { NewEntity } from './views/new-entity/view-new-entity';
import { Goals } from './views/goals/view-goals';
import { GoalDetails } from './views/goal-details/view-goal-details';
import { Profile } from './views/profile/view-profile';
import { UserGoal } from './views/user-goal/view-user-goal';
import { Editor } from './views/editor/view-editor';
import { Learn } from './views/learn/view-learn';
import { UserService } from './services/user-service';
import { IPublicProfile } from './data/interfaces';
import { ApolloService } from './services/apollo-service';
import { NewResource } from './views/new-resource/view-new-resource';

// Configure Firebase.
const config = {
  apiKey: 'AIzaSyDO6qjJ4Oeaiy3xQ3SfZjp68CsGfbn8Kb4',
  authDomain: 'wexare-dev.firebaseapp.com'
};
firebase.initializeApp(config);

interface IAppState {
  showCheatSheet: boolean;
  isSignedIn: boolean;
}

class App extends React.Component<any, IAppState> {
  homeRef: RefObject<Home>;

  unregisterAuthObserver: firebase.Unsubscribe | null = null;

  constructor(props: any) {
    super(props);
    this.state = {
      isSignedIn: false, // Local signed-in state.
      showCheatSheet: false
    };
    this.homeRef = React.createRef();
  }

  _handleKeyDown = (event: KeyboardEvent) => {
    // event.preventDefault();
    // this.homeRef.current?.handleKeyPress(event);
    switch (event.key) {
      case ' ':
        // this._toggleCheatSheet();
        break;
    }
  };

  _toggleCheatSheet() {
    this.setState({
      showCheatSheet: !this.state.showCheatSheet
    });
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleKeyDown);
    this.unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged(async (user) => {
        await UserService.getCurrentUser();
        this.setState({
          isSignedIn: !!user
        });
      });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleKeyDown);
    this.unregisterAuthObserver && this.unregisterAuthObserver();
  }

  render() {
    return (
      <ApolloProvider client={ApolloService.client}>
        {this.state.showCheatSheet && (
          <Segment>
            <List>
              <List.Item icon="arrow right" content={'Next'} />
              <List.Item icon="arrow left" content={'Previous'} />
              <List.Item icon="arrow up" content={'More details'} />
              <List.Item icon="arrow down" content={'Less details'} />
            </List>
          </Segment>
        )}
        <Router>
          {<WXNavBar isSignedIn={this.state.isSignedIn} />}
          <Switch>
            <Route path="/learn/:topic?"
              render={({ match }) =>
                <Learn match={match}
                  keyProp={Math.random()}
                  key={Math.random()} />
              } />
            <Route path="/resource/new">
              <NewResource></NewResource>
            </Route>
            <Route path="/editor">
              <Editor></Editor>
            </Route>
            <Route path="/goals/:id">
              <GoalDetails></GoalDetails>
            </Route>
            <Route path="/goals">
              <Goals></Goals>
            </Route>
            <Route path="/about">
              <Header>ABOUT</Header>
            </Route>
            <Route path="/users">
              <Header>USERS</Header>
            </Route>
            <Route path="/new">
              <NewEntity></NewEntity>
            </Route>
            <Route path="/login">
              <LogIn></LogIn>
            </Route>
            <Route path="/home">
              <Home ref={this.homeRef} title="weXare"></Home>
            </Route>
            <Route path="/:user/goals/:goalid" render={({ match }) => <UserGoal match={match} />} />
            <Route path="/:username" render={({ match }) => <Profile match={match} />} />
            <Route path="/">
              <WIP></WIP>
            </Route>
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}

function WXNavBar(props: any) {
  const [user, setUser] = React.useState({} as IPublicProfile);
  const getUser = async () => {
    const currentUser = await UserService.getCurrentUser();
    if (currentUser) {
      setUser(currentUser);
    }
  };

  React.useEffect(() => {
    if (!user) {
      getUser();
    }
  });

  const location = useLocation();
  // const currentUser = await UserService.getCurrentUser();
  // const currentUserName = (currentUser) ? currentUser.name : "";
  if ((location.pathname !== '/') && (location.pathname.indexOf("learn") < 0)) {
    return (
      <Segment>
        <Container>
          <Button as={Link} to="/home" icon="home" content={'Home'}></Button>
          <Button as={Link} to="/goals" icon="bullseye" content={'Goals'}></Button>
          <Button as={Link} to="/sachaa" icon="user" content={'Sacha'}></Button>
          <Button as={Link} to="/jsmith" icon="user" content={'John'}></Button>
          {props.isSignedIn && (
            <div
              style={{ display: 'inline-block', cursor: 'pointer', float: 'right' }}
              onClick={() => UserService.logOut()}
            >
              <Image
                src={user && user.avatar}
                circular
                size="mini"
                style={{ display: 'inline-block', marginRight: '.5rem' }}
              />{' '}
							Log out
            </div>
          )}
          {!props.isSignedIn && (
            <Button as={Link} to="/login">
              Log in
            </Button>
          )}
        </Container>
      </Segment>
    );
  }
  return null;
}

export default App;
