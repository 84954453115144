import React, { RefObject } from 'react';
import { Grid } from 'semantic-ui-react';
import './view-home.scss';
import { IEntity } from '../../data/interfaces';
import { EntityCard } from '../../components/entity-card/component-entity-card';
import { SampleData } from '../../data/sample-data';

export interface IHome {
    title: string;
}

export class Home extends React.Component<IHome, any> {
    cardRef: RefObject<EntityCard>;

    constructor(props: IHome) {
        super(props);

        this.cardRef = React.createRef();

        const cardCount = SampleData.entityList.items.length;
        const rndEntityIndex = Math.floor(Math.random() * cardCount);
        const rndEntity: IEntity = SampleData.entityList.items[rndEntityIndex];

        this.state = {
            list: SampleData.entityList,
            entityDisplayCount: 1,
            activeIndex: rndEntityIndex,
            activeEnties: [rndEntity],
            activeEntity: rndEntity,
            isSignedIn: false // Local signed-in state.
        };
    }

    private _getEntityList(index: number, count: number, length: number): number[] {
        const indexList: number[] = [];
        indexList.push(index);
        for (let i = 1; i < count; i++) {
            indexList.push((index + i) % length);
        }
        return indexList;
    }

    public handleKeyPress(e: KeyboardEvent) {
        const currentIndex = this.state.list.items.indexOf(this.state.activeEntity);
        switch (e.key) {
            case 'j': // NEXT
            case 'ArrowRight': // NEXT
                const nextIndex = (currentIndex + 1) % this.state.list.items.length;
                const newEntitySetNext = this._getEntityList(
                    nextIndex,
                    this.state.entityDisplayCount,
                    this.state.list.items.length
                ).map((index) => this.state.list.items[index]);
                this.setState({
                    activeEnties: newEntitySetNext,
                    activeIndex: nextIndex,
                    activeEntity: this.state.list.items[nextIndex]
                });
                break;
            case 'k': // PREVIOUS
            case 'ArrowLeft': // NEXT
                const previousIndex = Math.abs(
                    (this.state.list.items.length + currentIndex - 1) % this.state.list.items.length
                );
                const newEntitySetPrevious = this._getEntityList(
                    previousIndex,
                    this.state.entityDisplayCount,
                    this.state.list.items.length
                ).map((index) => this.state.list.items[index]);
                this.setState({
                    activeEnties: newEntitySetPrevious,
                    activeIndex: previousIndex,
                    activeEntity: this.state.list.items[previousIndex]
                });
                break;
            case 'l': // LIKE
                break;
            case '+': // MORE
                const newCount = this.state.entityDisplayCount * 2;
                const newEntitySet = this._getEntityList(currentIndex, newCount, this.state.list.items.length).map(
                    (index) => this.state.list.items[index]
                );
                this.setState({
                    entityDisplayCount: newCount,
                    activeEnties: newEntitySet
                });
                break;
            case 'ArrowDown': // NEXT
                this.cardRef.current!.showMore();
                break;
            case '-': // LESS
                const newCountLess = this.state.entityDisplayCount >= 2 ? this.state.entityDisplayCount / 2 : 1;
                const newEntitySetLess = this._getEntityList(
                    currentIndex,
                    newCountLess,
                    this.state.list.items.length
                ).map((index) => this.state.list.items[index]);
                this.setState({
                    entityDisplayCount: newCountLess,
                    activeEnties: newEntitySetLess
                });
                break;
            case 'ArrowUp': // NEXT
                this.cardRef.current?.showLess();
                break;
            case '*': // MULTIPLY
                break;
            case '/': // DIVIDE
                break;
        }
    }

    render() {
        return (
            <>
                <Grid centered style={{ marginTop: '30vh', display: 'none' }}>
                    {this.state.activeEnties.map((e: IEntity, index: number) => (
                        <EntityCard {...e} key={index} ref={this.cardRef}></EntityCard>
                    ))}
                </Grid>
            </>
        );
    }
}
