import React from 'react';
import { Container, Header, Button } from 'semantic-ui-react';
import './view-goal-details.scss';
import { Link } from 'react-router-dom';

export interface IGoalDetailsProps {
    id?: string;
}

export class GoalDetails extends React.Component<IGoalDetailsProps> {

    render() {
        return (
            <Container>
                <Button as={Link} to="/goals" icon="arrow left" content={"Back to Goals"}></Button>
                <Header as="h2">Goal Details {this.props.id}</Header>
            </Container>
        );
    }
}