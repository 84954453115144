import * as firebase from 'firebase/app';
import { IPublicProfile } from '../data/interfaces';
import { ApolloService } from './apollo-service';
import { GetUserByUidDocument } from '../data/generated/ApolloComponents';

export class UserService {
	public static currentUser: IPublicProfile | null | undefined = undefined;

	public static async getCurrentUser(): Promise<IPublicProfile | null | undefined> {
		const firebaseUser = firebase.auth().currentUser;
		if (firebaseUser) {
			const userProfile = await ApolloService.client
				.query({
					query: GetUserByUidDocument,
					variables: {
						uid: firebaseUser.uid
					}
				})
				.then((result) => {
					return result.data.getUserByUid;
				});

			if (firebaseUser) {
				UserService.currentUser = {
					id: userProfile.id,
					name: firebaseUser.displayName ?? '',
					uid: firebaseUser.uid,
					avatar: firebaseUser.photoURL ?? ''
				};
			}
		} else {
			UserService.currentUser = null;
		}
		return UserService.currentUser;
	}

	public static async logOut() {
		UserService.currentUser = undefined;
		await firebase.auth().signOut();
	}
}
