import React from 'react';
import { Card, Image, Icon, Header, List, Grid } from 'semantic-ui-react';
import './component-entity-card.scss';
import { IEntity, IRelated, RelationshipType } from '../../data/interfaces';
import Moment from 'react-moment';

export class EntityCard extends React.Component<IEntity, any>{

    constructor(props: IEntity) {
        super(props);
        this.state = {
            detailLevel: 2
        };
    }

    public showLess() {
        this.setState({
            detailLevel: (this.state.detailLevel - 1 <= 1) ? 1 : this.state.detailLevel - 1
        });
    }

    public showMore() {
        this.setState({
            detailLevel: (this.state.detailLevel + 1 >= 5) ? 5 : this.state.detailLevel + 1
        });
    }

    private _relationshipIcon(rel: IRelated) {
        switch (rel.type) {
            case RelationshipType.externalLink:
                return "linkify";
            case RelationshipType.image:
                return "image";
            case RelationshipType.video:
                return "video";
        }
    }

    render() {
        return (
            <Card style={{ textAlign: "left" }}>
                <Image src={this.props.bannerImage}
                    wrapped
                    ui={false}></Image>
                {
                    (this.state.detailLevel > 1) && <Card.Content>
                        <Card.Header content={this.props.name}></Card.Header>
                        {
                            (this.state.detailLevel > 3) && <Card.Meta>
                                <span className='date'>
                                    <Moment fromNow date={this.props.created}></Moment>
                                </span>
                            </Card.Meta>
                        }
                        {
                            (this.state.detailLevel > 4) && <Card.Description>
                                {this.props.description}
                                <Header as="h4" style={{ marginTop: "1rem" }}>Related</Header>
                                <List>
                                    {
                                        this.props.related.map((rel, index) =>
                                            <List.Item
                                                icon={this._relationshipIcon(rel)}
                                                content={<a
                                                    href={rel.link}
                                                    rel="noopener noreferrer"
                                                    target="_blank">
                                                    {rel.name}</a>}
                                                key={index}>
                                            </List.Item>
                                        )
                                    }
                                </List>
                            </Card.Description>
                        }
                    </Card.Content>
                }
                {
                    (this.state.detailLevel > 2) && <Card.Content extra>
                        <Grid columns={2}>
                            <Grid.Column>
                                <Icon name='heart' />
                                {this.props.likeCount} likes
                            </Grid.Column>
                            <Grid.Column>
                                <Icon name='user' />
                                {this.props.followCount} followers
                            </Grid.Column>
                        </Grid>
                    </Card.Content>
                }

            </Card>
        );
    }
}