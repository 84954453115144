import React from 'react';
import { Image } from 'semantic-ui-react';
import './view-wip.scss';

export class WIP extends React.Component {
    render() {
        return (
            <div style={{
                height: "100vh",
                width: "100vw",
                textAlign: "center",
                verticalAlign: "middle",
                display: "table-cell",
                padding: "10vw"
            }} >
                <Image centered verticalAlign="middle" src='wexare-logo.png' />
            </div>
        );
    }
}