import React from 'react';
import { Header, Container, Form, Button } from 'semantic-ui-react';
import './view-new-resource.scss';
import {
    useAddResourceMutation
} from '../../data/generated/ApolloComponents'
import { UrlUtils } from '../../utils/url-utils';
import { Link } from 'react-router-dom';

export function NewResource() {
    const [resUrl, setResUrl] = React.useState("https://www.tiktok.com/@socialtypro/video/6848248237376228614");
    const [resName, setResName] = React.useState("Spy on your competitors! pt 3");
    const [resTopics, setResTopics] = React.useState("seo,marketing,business,entrepreneurship");
    const [addResourceMutation] = useAddResourceMutation();

    return (
        <Container>
            <Header as="h1">New TikTok resource</Header>
            <Form onSubmit={async e => {
                await saveTikTok(resUrl, resName, resTopics, addResourceMutation);
                setResName("");
                setResUrl("");
                setResTopics("");
                UrlUtils.goTo("/learn");
            }}>
                <Form.Field>
                    <Form.Input fluid
                        value={resUrl}
                        onChange={e => setResUrl(e.target.value)}
                        label='TikTok URL'
                        placeholder='https://www.tiktok.com/@...' />
                </Form.Field>
                <Form.Field>
                    <Form.Input fluid
                        value={resName}
                        onChange={e => setResName(e.target.value)}
                        label='Title'
                        placeholder='Video description' />
                </Form.Field>
                <Form.Field>
                    <Form.Input fluid
                        value={resTopics}
                        onChange={e => setResTopics(e.target.value)}
                        label='Topics (comma separated)'
                        placeholder='topic 1, topic 2, etc' />
                </Form.Field>
                <Button
                    type='submit'
                    disabled={
                        (resUrl.length <= 0) &&
                        (resName.length <= 0)
                    }
                    content="Add TikTok"
                />
                <Button
                    as={Link}
                    secondary
                    to="/learn"
                    content="Cancel"
                />
            </Form>
        </Container>
    );
}

async function saveTikTok(url: string, name: string, topics: string, addResourceMutation: any) {
    const urlComponents = getTikTokUrlComponents(url);
    const tikTokResource = {
        name: name,
        link: url,
        source: "tiktok",
        type: "video",
        topics: topics.split(",")
            .map((t) => t.trim()
                .toLowerCase())
            .filter(t => t && t.length > 0),
        ...urlComponents
    }
    await addResourceMutation({
        variables: tikTokResource
    });
}

function getTikTokUrlComponents(url: string) {
    if (url && url.length > 0) {
        const urlComps = url.split("/");
        if (urlComps.length === 6) {
            return {
                sourceId: urlComps[5],
                author: urlComps[3].substring(1)
            }
        }
    }
    return undefined;
}