import React from 'react';
import { Form } from 'semantic-ui-react';
import './component-add-related-goal.scss';
import { UserService } from '../../services/user-service';
import { IGoal } from '../../data/interfaces';
import {
    useNewUserGoalMutation,
    useLinkUserGoalsMutation,
    GetUserGoalsDocument
} from '../../data/generated/ApolloComponents'

export function AddRelatedGoal(props: any) {
    const [goalName, setGoalName] = React.useState("");
    const [existingGoal, setExistingGoal] = React.useState("none");
    const [newGoal] = useNewUserGoalMutation({
        refetchQueries: [
            {
                query: GetUserGoalsDocument,
                variables: {
                    userId: UserService.currentUser?.id
                }
            }
        ]
    });
    const [linkGoals] = useLinkUserGoalsMutation({
        refetchQueries: [
            {
                query: GetUserGoalsDocument,
                variables: {
                    userId: UserService.currentUser?.id
                }
            }
        ]
    });

    let goalSelect: any[] = [];
    if (props.type === "why") {
        goalSelect = props.goals?.map((g: IGoal) => {
            return {
                key: g.id,
                text: g.name,
                value: g.id
            }
        })
            .filter((g: any) => g.key !== props.goal.id);
    }
    else if (props.type === "how") {
        goalSelect = props.goals?.filter((g: any) => {
            return g.whys?.length === 0;
        })
            .map((g: any) => {
                return {
                    key: g.id,
                    text: g.name,
                    value: g.id
                }
            })
            .filter((g: any) => g.key !== props.goal.id);
    }
    goalSelect.unshift({
        key: "none",
        text: "Select a goal",
        value: "none"
    })

    return (
        <>
            <Form onSubmit={e => {
                e.preventDefault();
                if (props.type === "why") {
                    saveGoalWhy(props.goal.id, goalName, existingGoal, newGoal, linkGoals);
                }
                else if (props.type === "how") {
                    saveGoalHow(props.goal.id, goalName, existingGoal, newGoal, linkGoals);
                }
            }}>
                <Form.Group widths='equal'>
                    <Form.Input
                        fluid
                        value={goalName}
                        disabled={(existingGoal.length === 0) &&
                            (existingGoal !== "none")}
                        onChange={e => setGoalName(e.target.value)}
                        label="New goal"
                        placeholder="Goal name"
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select
                        fluid
                        disabled={goalName.length > 0}
                        value={existingGoal}
                        onChange={(e, { value }) => {
                            setGoalName("");
                            setExistingGoal(`${value}`);
                        }}
                        label='Existing goal'
                        options={goalSelect ?? []}
                    />
                </Form.Group>
                <Form.Button
                    fluid
                    disabled={
                        (goalName.length <= 0) &&
                        ((existingGoal.length <= 0) ||
                            (existingGoal === "none"))
                    }
                    type="submit"
                    style={{ marginTop: "1.8rem" }}>
                    Add goal
                    </Form.Button>
            </Form>
        </>
    );
}

function saveGoalWhy(
    currentGoal: string,
    newGoalName: string,
    existingGoalId: string,
    newGoalMutation: any,
    linkGoalMutation: any) {
    if (newGoalName) {
        newGoalMutation({
            variables: {
                userId: UserService.currentUser?.id,
                name: newGoalName,
                description: "",
                linkedGoalId: currentGoal,
                isLinkedGoalWhy: false,
                isLinkedGoalHow: true
            }
        });
    }
    else if (existingGoalId) {
        linkGoalMutation({
            variables: {
                userId: UserService.currentUser?.id,
                parentGoalId: existingGoalId,
                childGoalId: currentGoal
            }
        });
    }
}

function saveGoalHow(
    currentGoal: string,
    newGoalName: string,
    existingGoalId: string,
    newGoalMutation: any,
    linkGoalMutation: any) {
    if (newGoalName) {
        newGoalMutation({
            variables: {
                userId: UserService.currentUser?.id,
                name: newGoalName,
                description: "",
                linkedGoalId: currentGoal,
                isLinkedGoalWhy: true,
                isLinkedGoalHow: false
            }
        });
        //TODO: Handle exceptions (eg. Goal already exists)
    }
    else if (existingGoalId) {
        linkGoalMutation({
            variables: {
                userId: UserService.currentUser?.id,
                parentGoalId: currentGoal,
                childGoalId: existingGoalId
            },
        });
    }
}