export interface IPublicProfile {
    id?: string;
    uid?: string;
    name?: string;
    avatar?: string;
}

export enum EntityUpdateType {
    creation,
    contentUpdate,
}

export interface IEntityUpdate {
    updated: Date;
    updatedBy: IPublicProfile;
    type: EntityUpdateType,
    content: {
        name: string,
        description: string,
        related: IRelated[]
    }
}

export enum RelationshipType {
    externalLink,
    image,
    video
}

export interface IRelated {
    id: string;
    type: RelationshipType;
    name: string;
    link: string;
}

export interface IMessage {
    id: string;
    text: string;
    posted: Date;
    author: IPublicProfile;
}

export interface IThread {
    id: string;
    messages: IMessage[];
}

export interface IEntity {
    id: string;
    name: string;
    type: string;
    description: string;
    bannerImage: string;
    likeCount: number;
    followCount: number;
    created: Date;
    createdBy: IPublicProfile;
    related: IRelated[];
    updateHistory: IEntityUpdate[];
    conversation?: IThread;
}

export interface IGoalResource {
    id: string;
    createdBy: IPublicProfile;
    resource: IEntity;
    likeCount: number;
    private: boolean; // can only change from private -> public
}

export interface IResource {
    id: string;
    name?: string;
    author?: string;
    type?: string;
    source?: string;
    link?: string;
    sourceId?: string;
    topics?: string[]
}

export interface IList {
    id: string;
    name: string;
    description: string;
    created: Date;
    createdBy: IPublicProfile;
    items: IEntity[];
    conversation?: IThread;
}

export interface ICoordinate {
    lon: number;
    lat: number;
}

export interface ILocation {
    id: string;
    name: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    coordinates?: ICoordinate;
}

export enum RepeatCycle {
    hourly,
    daily,
    weekly,
    monthly,
    yearly,
}

export interface ITime {
    id: string;
    start?: Date;
    end?: Date;
    repeat?: boolean;
    repeatEvery?: RepeatCycle;
    duration?: boolean; // duration in milliseconds
}

export interface IGoal {
    id: string;
    name: string;
    description: string;
    resources: IGoalResource[];
    createdBy: IPublicProfile;
    created: Date;
    updatedBy: IPublicProfile;
    updated: Date;
    ownedBy: IPublicProfile[];
    when?: ITime;
    location?: ILocation[];
    shareCount: number;
    likeCount: number;
    conversation?: IThread;
    // TODO: private: boolean;
    // TODO: sameAsGoalIds: string[];
    // TODO: acl: Access control list
}

export interface IGoalLink {
    id: string;
    goalPreId: string;
    goalPostId: string;
    created: Date;
}

export interface IGoalTree {
    node: IUserGoal;
    children: IGoalTree[];
}

export interface IUserGoal {
    id: string;
    goal: IGoal;
}

export interface IUserGoalLink {
    id: string;
    preGoalId: string;
    postGoalId: string;
}

