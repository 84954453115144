import React, { createRef } from 'react';
import { Container, Header, Icon, Input, Form, List } from 'semantic-ui-react';
import './view-goals.scss';
import { GoalsService } from '../../services/goals-service'
import { IGoalTree } from '../../data/interfaces';
import {
    useGetUserGoalsQuery
} from '../../data/generated/ApolloComponents'

interface IGoalsState {
    showNewGoalFormUnder: string;
}

export class Goals extends React.Component<any, IGoalsState> {

    inputRef: React.RefObject<Input> = createRef()

    constructor(props: any) {
        super(props);
        this.state = {
            showNewGoalFormUnder: ""
        };
    }

    newGoal(goalId: string) {
        this.setState({
            showNewGoalFormUnder: goalId
        });
        setTimeout(() => {
            this.inputRef.current?.focus();
        }, 0);
    }

    showNewGoalFormUnder(goalId: string): boolean {
        return this.state.showNewGoalFormUnder === goalId;
    }

    renderListNodeItem(node: IGoalTree, index: number, depth: number) {
        return <div>
            <div className={"ui padded goal-item"}>
                <Icon name="minus" className={"goal-icon"} />
                <div className={"goal-details"} >
                    <div className={"goal-title"}>{node.node.goal.name}</div>
                    <div className={"goal-description"}>{node.node.goal.description}</div>
                </div>
            </div>
            <div className={"goal-separator"} onClick={() => this.newGoal(node.node.goal.id)}>
                {
                    !this.showNewGoalFormUnder(node.node.goal.id) &&
                    <div className={"goal-separator-divider"}></div>
                }
                {
                    this.showNewGoalFormUnder(node.node.goal.id) &&
                    <div className={"new-goal-inline-form"}>
                        <Form>
                            <Input ref={this.inputRef} style={{ width: "100%" }}></Input>
                        </Form>
                    </div>
                }
            </div>
            {
                node.children.map((n, nodeindex) =>
                    this.renderTreeNode(n, index * 1000 + nodeindex, depth + 1))
            }
        </div>;
    }

    renderTreeNode(node: IGoalTree, index: number, depth: number): JSX.Element {
        return <div key={index} className={`goal-tree ${(depth !== 0) ? "inner-goal" : ""}`}>
            {
                this.renderListNodeItem(node, index, depth)
            }
        </div>;
    }

    render() {
        return (
            <Container>
                <Header as="h2" style={{ marginTop: "2rem" }}>Goals</Header>
                {
                    GoalsService.getMyGoals("user123").map((goalTree, index) => {
                        return this.renderTreeNode(goalTree, index, 0);
                    })
                }
                <GoalsList></GoalsList>
            </Container>
        );
    }
}

export function GoalsList() {

    const { loading, error, data } = useGetUserGoalsQuery({
        variables: {
            userId: "sachaa"
        }
    });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return <>
        <Header as="h1">Goals</Header>
        <List as="ul">
            {
                data!.getUserGoals!.map((g: any) => <List.Item as="li" key={g.id}>{g.name}</List.Item>)
            }
        </List>
    </>
}