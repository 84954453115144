
import React from 'react';
import { Container, Button, Header, List, Grid } from 'semantic-ui-react';
import './view-user-goal.scss';
import { Link } from 'react-router-dom';
import { UserService } from '../../services/user-service';
import { AddRelatedGoal } from '../../components/add-related-goal';
import {
    useGetGoalQuery,
    useGetUserGoalsQuery
} from '../../data/generated/ApolloComponents'

export function UserGoal({ match }: any) {
    const { user } = match.params;
    return (
        <Container>
            <Button as={Link} icon="arrow left" to={`/${user}`} content={`Back to ${user}'s goals`}></Button>
            <UserGoalDetails {...match.params} />
        </Container>
    );
}

function UserGoalDetails(props: any) {
    const goalsQueryResult = useGetUserGoalsQuery({
        variables: {
            userId: props.user
        }
    });
    const userGoalQueryResult = useGetGoalQuery({
        variables: {
            userId: props.user,
            goalId: props.goalid
        }
    });
    const [showAddWhy, setShowAddWhy] = React.useState(false);
    const [showAddHow, setShowAddHow] = React.useState(false);

    if (userGoalQueryResult.loading || goalsQueryResult.loading) return <p>Loading...</p>;
    if (userGoalQueryResult.error) return <p>Error :(</p>;
    if (goalsQueryResult.error) return <p>Error :(</p>;

    const userGoal = userGoalQueryResult.data!.getGoal;
    const goals = goalsQueryResult.data!.getUserGoals;

    const whys = userGoal!.path!.split(".")
        .map((goalId: string) => goals!.find((g: any) => g.id === goalId))
        .filter((w: any) => w)
        .reverse();
    const hows = userGoal!.hows;

    return (
        <>
            <Header as="h1" content={userGoal!.name}></Header>
            <Container>{userGoal!.description}</Container>
            <Grid style={{ marginTop: "2rem" }}>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Header as="h2">
                            Why
                        </Header>
                        {
                            (whys.length <= 0) &&
                            (UserService.currentUser?.id === props.user) &&
                            !showAddWhy &&
                            <Button
                                onClick={() => setShowAddWhy(true)}
                                icon="plus"
                                content={"Add a reason"} />
                        }
                        {
                            showAddWhy &&
                            <AddRelatedGoal goal={userGoal} goals={goals} type="why" />
                        }
                        <List as="ul">
                            {
                                whys.map((w: any) =>
                                    <List.Item key={w.id} as="li">
                                        <Link to={`/${props.user}/goals/${w.id}`}>{w.name}</Link>
                                    </List.Item>)

                            }
                        </List>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as="h2">
                            Hows
                        </Header>
                        {
                            (UserService.currentUser?.id === props.user) &&
                            !showAddHow &&
                            <Button
                                icon="plus"
                                onClick={() => setShowAddHow(true)}
                                content={"Add your next step"} />
                        }
                        {
                            showAddHow &&
                            <AddRelatedGoal goal={userGoal} goals={goals} type="how" />
                        }
                        <List as="ul">
                            {
                                hows!.map((h: any) =>
                                    <List.Item key={h.id} as="li">
                                        <Link to={`/${props.user}/goals/${h.id}`}>{h.name}</Link>
                                    </List.Item>)
                            }
                        </List>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    );
}